import MP360_LOGO from "../../assets/mp360_logo.svg";
import MOBILE_ICON from "../../assets/mobile_icon.svg";
import LANG_ICON_EN from "../../assets/lang_icon_en.svg";
import LANG_ICON_HI from "../../assets/lang_icon_hi.svg";
import LANG_ICON_PA from "../../assets/lang_icon_pa.svg";
import CALL_ICON from "../../assets/call_icon.svg";
import CALL_ICON_MWEB from "../../assets/call_icon_mweb.svg";
// import SEARCH_ICON from '../../assets/search_mobile.svg';
import HAM_ICON from "../../assets/hamburger.svg";
import { Link, useLocation } from "react-router-dom";
import downloadIcon from "../../assets/download_nobg.png";
import Button from "../Button/Button";
import { Trans, useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { PHONE_NUMBER } from "../../constants";

function Navbar() {
  let openSidebar = () => {
    let sidebar = document.querySelector("#mobile_sidebar") as HTMLElement;
    if (sidebar.style) {
      sidebar.style.transform = "translateX(0%)";
    }
    let body = document.querySelector("body") as HTMLElement;
    body.style.overflow = "hidden";
  };
  let closeSidebar = () => {
    let sidebar = document.querySelector("#mobile_sidebar") as HTMLElement;
    let body = document.querySelector("body") as HTMLElement;
    body.style.overflow = "";
    if (sidebar.style) {
      sidebar.style.transform = "translateX(100%)";
    }
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Keep dropdown open if we are on a dropdown item page
    if (location.pathname === '/buy-cattle' || location.pathname === '/sell-cattle') {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  }, [location.pathname]);

  const handleToggleDropdown = (e:any) => {
    e.preventDefault();
    setDropdownVisible(!dropdownVisible);
  }

  let buyCattle = [
    {
      name: "buy_cattle",
      feat: "buy_cattle_sub",
      link: "/buy-cattle",
    },
    {
      name: "check_cattle_price",
      feat: "check_cattle_price_sub",
      link: "/sell-cattle",
    },
  ];

  let cattleFeed = [
    {
      name: "combo_offer",
      feat: "combo_sub",
      link_key: "combo_offer",
    },
    {
      name: "khal",
      feat: "binola_khal_sub",
      link_key: "khal",
    },
    {
      name: "goli_khal",
      feat: "goli_khal_sub",
      link_key: "goli_khal",
    },
    {
      name:  "mineral_mix",
      feat:  "mineral_mix_sub",
      link_key: "mineral_mix",
    },
    {
      name: "silage",
      feat: "silage_sub",
      link_key: "silage",
    },
    {
      name: "traditional_khal",
      feat: "traditional_khal_sub",
      link_key: "traditional_khal",
    },
  ];

  const { t, i18n } = useTranslation();
  const lngs = {
    en: {
      nativeName: "English",
    },
    hi: {
      nativeName: "हिन्दी",
    },
    pa: {
      nativeName: "ਭਾਸ਼ਾ",
    },
  };

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    let dd = document.querySelector("#cattle-feed-dropdown") as HTMLElement;
    dd.style.visibility = "visible";
    dd.style.opacity = "1";
  };

  const handleMouseLeave = () => {
    let dd = document.querySelector("#cattle-feed-dropdown") as HTMLElement;
    const newTimer = setTimeout(() => {
      dd.style.visibility = "hidden";
      dd.style.opacity = "0";
    }, 200); // Adjust the delay time as needed
    setTimer(newTimer);
  };

  const handleDropdownLeave = () => {
    let dd = document.querySelector("#cattle-feed-dropdown") as HTMLElement;
    dd.style.visibility = "hidden";
    dd.style.opacity = "0";
    if (timer !== null && timer !== undefined) {
      clearTimeout(timer);
    }
  };

  const PlaystoreSection = () => (
    <div className="nav__menu-item--playstore">
      <div className="navLabel">
      <Trans
              i18nKey="Download_Button_sub"
              components={{
                span: <span className="green" />,
              }}
            />
      </div>

      <div className="nav__menu-item--playstore--cta" >
        <Button primary playStore  text={t("download_app")} className="navButton" />
        <Button outline text={t("contact_us")} color="#2CAC4C" to="/contact-us" className="navButton" />
      </div>
    </div>
  );

  return (
    <>
      <nav className="nav">
        <div
          className="nav__download"
          onClick={() => {
            window.open(
              "https://play.google.com/store/search?q=merapashu360&c=apps",
              "_blank"
            );
          }}
          style={{ cursor: "pointer" }}
        >
          <div className="nav__download--text">
            <div className="nav__download--text-main">
              8 {t("lakh_downloads")}
            </div>

            <div className="nav__download--text-sub">{t("nav_subtext")}</div>
          </div>
          <a
            href="https://play.google.com/store/search?q=merapashu360&c=apps"
            target={"_blank"}
            rel="noreferrer"
          >
            <div className="lineBreak">
              <Button text={t("download_mob")} primary />
            </div>
          </a>
        </div>
        <div className="nav__container">
          <div className="nav__left">
            <Link to={"/"}>
              <img src={MP360_LOGO} alt="MP360 LOGO" className="nav__logo" />
            </Link>
          </div>
          <div className="nav__right">
            <ol className="nav__options">
              <li className="nav__option">
                {i18n.language === "en" ? (
                  <img
                    src={LANG_ICON_EN}
                    alt="Language Icon"
                    className="nav__option--icon"
                  />
                ) : null}

                {i18n.language === "hi" ? (
                  <img
                    src={LANG_ICON_HI}
                    alt="Language Icon"
                    className="nav__option--icon"
                  />
                ) : null}

                {i18n.language === "pa" ? (
                  <img
                    src={LANG_ICON_PA}
                    alt="Language Icon"
                    className="nav__option--icon"
                  />
                ) : null}
                <div className="nav__option--text">
                  <label htmlFor="lang_pref" className="nav__option--text-lang">
                    {t("nav_language")}
                  </label>
                  <select
                    name="lang_pref"
                    id="lang_pref"
                    value={i18n.language}
                    onChange={async (event) => {
                      await i18n.changeLanguage(event.target.value);
                      await localStorage.setItem(
                        "language",
                        event.target.value
                      );
                    }}
                  >
                    {Object.keys(lngs).map((lng: any, index: number) => (
                      <option key={index} value={lng}>
                        {lngs[lng as keyof typeof lngs].nativeName}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
              <li className="nav__option">
                <img
                  src={MOBILE_ICON}
                  alt="Mobile Icon"
                  className="nav__option--icon"
                />
                <div className="nav__option--text">
                  <label htmlFor="download_app">{t("nav_get")}</label>
                  <a
                    href="https://play.google.com/store/search?q=merapashu360&c=apps"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <button name="download_app" className="nav__option--action">
                      {t("nav_download_app")}
                    </button>
                  </a>
                </div>
              </li>
              <li className="nav__option">
                <img
                  src={CALL_ICON}
                  alt="CALL Icon"
                  className="nav__option--icon"
                />
                <div className="nav__option--text">
                  <label htmlFor="download_app">{t("nav_call")}</label>
                  <a
                    href={`tel:${PHONE_NUMBER}`}
                    // name="download_app"
                    className="nav__option--action"
                  >
                    {PHONE_NUMBER}
                  </a>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <hr className="h-line" />
        <div className="nav__container nav__container--menu">
          <div className="nav__menu">
            <div className="nav__menu-item-container" id={"cattle-feed"}>
              <a
                className="nav__menu-item"
                style={{ color: location.pathname === "/" ? "#034EA2" : "" }}
                href="/"
              >
                {t("home")}
              </a>
              <div className="dropdown">
                <a
                  className="nav__menu-item dropdown-toggle"
                  style={{
                    color: location.pathname === "/feed" ? "#034EA2" : "",
                  }}
                  href="/feed"
                  role="button"
                  id="dropdownMenuLinkk"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t("buy_cattle_feed")}
                </a>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLinkk"
                >
                  <div className="nav_text">{t("explore_categories")}:</div>
                  <div className="nav__menu-item--categoriesFeed">
                    {cattleFeed.map((feed, index: number) => {
                      return (
                        <HashLink key={index} to={`/feed/${feed.link_key}`}>
                          <div className="nav__menu-item--category">
                            <p className="nav__menu-item--category--name">
                              {t(feed.name)}
                            </p>
                            <p className="nav__menu-item--category--features">
                              {t(feed.feat)}
                            </p>
                          </div>
                        </HashLink>
                      );
                    })}
                  </div>
                  <PlaystoreSection />
                </div>
              </div>
              <div className="dropdown">
                <a
                  className="nav__menu-item dropdown-toggle"
                  style={{
                    color: location.pathname === "/buy-cattle" ? "#034EA2" : "",
                  }}
                  href="/buy-cattle"
                  role="button"
                  id="dropdownMenuLink"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t("buy_cattle")}
                </a>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <div className="nav_text">{t("explore_categories_one")}:</div>
                  <div className="nav__menu-item--categories">
                    {buyCattle.map((cattle, index) => (
                      <a key={index} href={cattle.link}>
                        <div className="nav__menu-item--category">
                          <p className="nav__menu-item--category--name">
                            {t(cattle.name)}
                          </p>
                          <p className="nav__menu-item--category--features">
                            {t(cattle.feat)}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                  <PlaystoreSection />
                </div>
              </div>

              <a
                className="nav__menu-item"
                style={{
                  color: location.pathname.includes("/blog") ? "#034EA2" : "",
                }}
                href="/blog"
              >
                {t("blog")}
              </a>
              <a
                className="nav__menu-item"
                style={{
                  color: location.pathname === "/career" ? "#034EA2" : "",
                }}
                href="https://careers.merapashu360.com/jobs/Careers"
                target="_blank"
                rel="noreferrer"
              >
                {t("career")}
              </a>
            </div>
          </div>
        </div>

        {/* Mobile Screen  */}
        <div className="nav__mobile">
          <div className="nav__mobile--item">
            <a href="/">
              <img src={MP360_LOGO} alt="Logo" className="nav__mobile--logo" />
            </a>
          </div>
          <div className="nav__mobile--item">
            <a
              href={`tel:${PHONE_NUMBER}`}
              // name="download_app"
              className="nav__option--action"
            >
              <img
                src={CALL_ICON_MWEB}
                alt="Call Icon"
                className="nav__mobile--call"
              />
            </a>
          </div>
          {/* <div className="nav__mobile--item">
            <img
              src={SEARCH_ICON}
              alt="Search Icon"
              className="nav__mobile--search"
            />
          </div> */}
          <div className="nav__mobile--item">
            {i18n.language === "en" ? (
              <img
                src={LANG_ICON_EN}
                alt="Lang Icon"
                className="nav__mobile--langIcon"
                onClick={() => i18n.changeLanguage("hi")}
              />
            ) : null}
            {i18n.language === "hi" ? (
              <img
                src={LANG_ICON_HI}
                alt="Lang Icon"
                className="nav__mobile--langIcon"
                onClick={() => i18n.changeLanguage("pa")}
              />
            ) : null}
            {i18n.language === "pa" ? (
              <img
                src={LANG_ICON_PA}
                alt="Lang Icon"
                className="nav__mobile--langIcon"
                onClick={() => i18n.changeLanguage("en")}
              />
            ) : null}
          </div>
          <div className="nav__mobile--item" onClick={openSidebar}>
            <img src={HAM_ICON} alt="Hamburger" className="nav__mobile--ham" />
          </div>
        </div>
        <div className="nav__mobile__sidebar" id="mobile_sidebar">
          <div
            className="nav__mobile__sidebar--film"
            onClick={closeSidebar}
          ></div>

          <div className="nav__mobile__sidebar--container">
            <div className="nav__mobile__sidebar--logo">
              <img src={MP360_LOGO} alt="logo" />
            </div>
            <ul className="nav__mobile__sidebar--menu">
              <li className="nav__mobile__sidebar--menu-item">
                <a
                  style={{
                    color: location.pathname === "/" ? "#034EA2" : "",
                  }}
                  href={"/"}
                >
                  {" "}
                  {t("home")}
                </a>
              </li>
              <li className="nav__mobile__sidebar--menu-item">
                <a
                  style={{
                    color: location.pathname === "/feed" ? "#034EA2" : "",
                  }}
                  href={"/feed"}
                >
                  {" "}
                  {t("buy_cattle_feed")}
                </a>
              </li>
              <li className="nav__mobile__sidebar--menu-item">
                <a
                  className="dropdown-toggle"
                  style={{
                    color: dropdownVisible ? "#034EA2" : "",
                  }}
                  href="#"
                  onClick={handleToggleDropdown}
                >
                   {t("buy_cattle")} 
                </a>
                {dropdownVisible && (
                  <ul className="nav_mobile_dropdown">
                    <li>
                      <a href="/buy-cattle"
                       style={{
                        color: location.pathname === "/buy-cattle" ? "#034EA2" : "",
                      }}
                      >{t("buy_cattle")}</a>
                    </li>
                    <li>
                      <a href="/sell-cattle"
                       style={{
                        color: location.pathname === "/sell-cattle" ? "#034EA2" : "",
                      }}
                      >
                        {t("check_cattle_price")}
                      </a>
                    </li>
                  </ul>
                )}
              </li>
              <li className="nav__mobile__sidebar--menu-item">
                <a
                  style={{
                    color: location.pathname === "/about-us" ? "#034EA2" : "",
                  }}
                  href={"/about-us"}
                >
                  {t("about_us")}
                </a>
              </li>
              <li className="nav__mobile__sidebar--menu-item">
                <a
                  style={{
                    color: location.pathname === "/contact-us" ? "#034EA2" : "",
                  }}
                  href={"/contact-us"}
                >
                  {t("contact_us")}
                </a>
              </li>

              <li className="nav__mobile__sidebar--menu-item">
                <a
                  style={{
                    color: location.pathname === "/career" ? "#034EA2" : "",
                  }}
                  href="https://careers.merapashu360.com/jobs/Careers"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("career")}
                </a>
              </li>

              <li className="nav__mobile__sidebar--menu-item">
                <a
                  style={{
                    color:
                      location.pathname === "/partnership-page"
                        ? "#034EA2"
                        : "",
                  }}
                  href={"/partnership-page"}
                  rel="noreferrer"
                >
                  {t("partnership_page")}
                </a>
              </li>
            </ul>
            <div className="nav__mobile__sidebar--footer">
              <div className="nav__mobile__sidebar--footer--contact_us">
                <a href={`tel:${PHONE_NUMBER}`} className="nav__option--action">
                  <img
                    src={CALL_ICON}
                    alt="CALL Icon"
                    className="nav__option--icon"
                  />
                </a>
                <div className="nav__option--text">
                  <label htmlFor="download_app">{t("nav_call")}</label>
                  <a href={`tel:${PHONE_NUMBER}`} className="nav__option--action">
                    {PHONE_NUMBER}
                  </a>
                </div>
              </div>
            </div>
            <div className="nav__mobile__sidebar--footer--download-container">
              <div className="nav__mobile__sidebar--footer--download">
                <img src={downloadIcon} alt="Download Icon" />
                <div className="nav__mobile__sidebar--footer--download-cta">
                  <h2> 8 {t("lakh_downloads")}</h2>
                  <p>
                    {t("nav_download_app")} & {t("nav_get")}
                  </p>
                </div>
              </div>
              <Button primary playStore text={t("nav_download_app")} />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
